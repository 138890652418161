.home-page {
  width: 100%;
  height: 100%;
  background-color: #001528;
}
.home-page .home-page-div {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
}
.home-page .home-page-div .home-page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 26px;
}
.home-page .home-page-div .home-page-title h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 30px;
}
.home-page .home-page-div .home-page-title img {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  margin: 0 20px 0 0;
  height: 38px;
}
.home-page .home-page-div .ant-input {
  background: #fff;
  display: flex;
  border-radius: 8px;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  border-radius: 24px;
  z-index: 3;
  height: 44px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.87);
}
.jianyue-home-page {
  width: 100%;
  height: 100%;
  background-color: #1b1e25 !important;
}

.btn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  background-color: rgb(103, 114, 229);
  -webkit-filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff,endColorstr=#e6e6e6,GradientType=0);
          filter: progid:dximagetransform.microsoft.gradient(startColorstr=#ffffff,endColorstr=#e6e6e6,GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #e6e6e6;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  *margin-left: 0.3em;
}
.btn:hover,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  background-color: #e6e6e6;
}
.btn-large {
  padding: 9px 14px;
  font-size: 15px;
  line-height: normal;
  border-radius: 5px;
}
.btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;
  background-position: 0 -15px;
  transition: background-position 0.1s linear;
}
.btn-primary,
.btn-primary:hover {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
}
.btn-primary.active {
  color: rgba(255, 255, 255, 0.75);
}
.btn-primary {
  background-color: rgb(103, 114, 229);
  border: 1px solid #3762bc;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.5);
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  -webkit-filter: none;
          filter: none;
  background-color: #4a77d4;
}
.btn-block {
  width: 100%;
  display: block;
}
* {
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

.login-div {
  width: 100%;
  height: 100%;
}

#login-div {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
  background-color: #001528;
}

#jianyue-login-div {
  background-color: #1b1e25 !important;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", sans-serif;
}

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width: 300px;
  height: 300px;
}
.login h1 {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  text-align: center;
  padding-bottom: 15px;
}
.login img {
  color: #fff;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 1px;
  padding-bottom: 15px;
  display: block;
  margin: auto;
  height: 50px;
}
.login-input {
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
  background-color: rgba(103, 114, 229, 0.2);
  color: #fff;
  border: none;
  outline: none;
  padding: 10px;
  font-size: 13px;
  border-radius: 4px;
}
.login-input:focus {
  background-color: #fff;
  color: #000000;
}

.header-filter-div {
  margin-left: 200px;
  width: 720px;
}
.header-filter-div .header-filter-div-form {
  border: 1px solid #e8e8e8;
  margin: 8px 8px 8px 0;
  padding: 24px 8px 16px 20px;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-row {
  display: flex;
  align-items: center;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-row .header-filter-div-form-name {
  width: 80px;
  margin-bottom: 12px;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-row .header-filter-div-form-name h5 {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: normal;
  text-align: left;
  line-height: 20px;
  margin-right: 20px;
  align-self: center;
  flex: 32% 1;
  text-transform: none;
  margin: 0;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-row .header-filter-div-form-value {
  width: 600px;
  margin-bottom: 12px;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-row .header-filter-div-form-value-advanced {
  width: 680px;
  margin-bottom: 12px;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-row .ant-slider-track {
  background-color: #6772e5;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-row .ant-slider-handle {
  border: solid 2px #6772e5;
}
.header-filter-div .header-filter-div-form .ant-divider-inner-text {
  color: #5f6368;
  font-weight: 100;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-expend-advanced {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-expend-advanced .header-filter-div-form-expend-advanced-span {
  cursor: pointer;
  color: #6772e5;
  text-align: right;
}
.header-filter-div .header-filter-div-form .header-filter-div-form-submit-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1200px) {
  .header-filter-div {
    margin-left: 100px;
    width: 720px;
  }
}
@media only screen and (max-width: 768px) {
  .header-filter-div {
    width: 720px;
  }
}
#header {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;
  height: 64px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.08);
}
#header .header-left-part {
  display: flex;
  align-items: center;
}
#header .header-left-part #logo {
  overflow: hidden;
  padding-left: 40px;
  float: left;
  line-height: 64px;
  text-decoration: none;
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#header .header-left-part #logo img {
  display: inline;
  vertical-align: middle;
  margin-right: 8px;
  width: 32px;
}
#header .header-left-part #logo span {
  color: #6772e5;
  outline: none;
  font-size: 22px;
  line-height: 32px;
  color: #2847c3;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-align: center;
}
#header .header-left-part #logo-jianyue {
  overflow: hidden;
  padding-left: 40px;
  float: left;
  line-height: 64px;
  text-decoration: none;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  cursor: pointer;
}
#header .header-left-part #logo-jianyue img {
  height: 32px;
  display: inline;
  vertical-align: middle;
}
#header .header-left-part .header-search-bar {
  display: flex;
  align-items: center;
  height: 64px;
  text-decoration: none;
  padding-left: 28px;
}
#header .header-left-part .header-search-bar .ant-input {
  color: #000;
  background: #fff;
  display: flex;
  border-radius: 8px;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  height: 39px;
  width: 638px;
  border-radius: 24px;
  z-index: 3;
  height: 44px;
  padding-left: 20px;
  padding-right: 8px;
  margin: 0 auto;
}
#header .header-left-part .header-search-bar .ant-input:hover,
#header .header-left-part .header-search-bar .ant-input:focus,
#header .header-left-part .header-search-bar .ant-inputactive {
  box-shadow: 0 3px 7px rgba(50, 50, 93, 0.1), 0 1px 3px rgba(50, 50, 93, 0.15), 0 2px 7px rgba(0, 0, 0, 0.1);
}
#header .header-left-part .header-search-bar .ant-input-suffix {
  z-index: 99;
  cursor: pointer;
}
#header .header-left-part .header-filter-button {
  width: 40px;
  height: 40px;
  margin-left: 8px;
  z-index: 99;
}
#header .header-left-part .header-filter-button .header-filter-botton-icon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#header .header-left-part .header-filter-button .header-filter-botton-icon .icon {
  height: 24px;
  width: 24px;
  fill: #5f6368;
}
#header .header-left-part .header-filter-button:hover {
  border-radius: 50%;
  background-color: #dfe1e5;
}
.header-meta {
  zoom: 1;
  padding-right: 40px;
  height: 64px;
}
.header-meta::before,
.header-meta::after {
  display: table;
  content: '';
}
.header-meta::after {
  clear: both;
}
.header-meta #preview {
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.candidate-list-div {
  margin-top: 10px;
}
.candidate-list-div .candidate-single {
  background: #fff;
  border-radius: 4px;
  margin: 0 7px 14px 7px;
  border: 1px solid #e8e8e8;
  padding: 10px 10px 24px 10px;
  cursor: pointer;
  display: flex;
  position: relative;
}
.candidate-list-div .candidate-single .candidate-single-id {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.25);
}
.candidate-list-div .candidate-single:focus,
.candidate-list-div .candidate-single:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.candidate-list-div .candidate-single .candidate-single-avatar {
  margin-right: 14px;
}
.candidate-list-div .candidate-single .candidate-single-avatar .purchased-sign {
  margin-top: 8px;
  width: 64px;
  text-align: center;
  background-color: #3ecf8e;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  line-height: 24px;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-basic-info {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-basic-info h1 {
  margin: 0;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #32325d;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-basic-info h4 {
  margin: 0;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #6772e5;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-basic-info h4 span {
  color: #e8e8e8;
  margin-left: 4px;
  margin-right: 4px;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-basic-info h4 #highlighter {
  color: red;
  margin-left: 0px;
  margin-right: 0px;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-exp {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 2px;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-exp h4 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: grey;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-exp h4 span {
  color: #e8e8e8;
  margin-left: 4px;
  margin-right: 4px;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-exp h4 #highlighter {
  color: red !important;
  margin-left: 0px;
  margin-right: 0px;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-exp .small-dot {
  height: 6px;
  width: 6px;
  padding: 3px;
  margin: 0 6px 0 0;
  border-radius: 50%;
  background-color: #6772e5;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-single-exp .detail {
  margin: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #525f7f;
  margin-bottom: 0;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-skills {
  margin-top: 8px;
  margin-right: 30px;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-skills .candidate-skills-tag {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
  border: 1px solid #adc6ff;
  border-radius: 4px;
  line-height: 23px;
  height: 25px;
  font-size: 22px;
  padding: 0 8px;
  margin-right: 8px;
  font-size: 12px;
  text-align: center;
  white-space: pre-wrap;
}
.candidate-list-div .candidate-single .candidate-single-info .candidate-skills .candidate-skills-tag #highlighter {
  color: #f5222d !important;
  background: #fff1f0 !important;
  font-size: 500;
}
.candidate-list-page-body-loading-inside {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  flex-direction: column;
  height: 120px;
}
.candidate-list-page-body-loading-inside .candidate-list-page-body-loading-text {
  padding-top: 10px;
  color: #6772e5;
  font-weight: 600;
}
.candidate-list-page-body-loading-inside .candidate-list-page-body-error-text {
  padding-top: 10px;
  color: #ff6644;
  font-weight: 500;
}

.resume .resume-div .single-resume-div {
  height: 100%;
  background-color: #fff;
}
.resume .resume-div .single-resume-div .resume-div-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 28px 24px 28px;
}
.resume .resume-div .single-resume-div .resume-div-header .resume-div-header-language {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
  border: 1px solid #adc6ff;
  border-radius: 4px;
  line-height: 23px;
  height: 25px;
  font-size: 22px;
  padding: 0 8px;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
}
.resume .resume-div .single-resume-div .resume-div-header .resume-div-header-language:first-child {
  margin-right: 8px;
}
.resume .resume-div .single-resume-div .resume-div-header .resume-div-header-language:nth-child(2) {
  color: #722ed1 !important;
  background: #f9f0ff !important;
  border-color: #d3adf7 !important;
}
.resume .resume-div .single-resume-div .resume-div-header .purchased {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  background-color: #3ecf8e;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #6772e5;
  text-decoration: none;
  transition: all 0.15s ease;
  color: #fff;
  background: #3ecf8e;
}
.resume .resume-div .single-resume-div .header-info {
  display: flex;
  align-items: center;
  padding: 0 28px 24px 28px;
  border-bottom: 1px solid #e8e8e8;
}
.resume .resume-div .single-resume-div .header-info .avatar {
  display: flex;
  margin-right: 24px;
}
.resume .resume-div .single-resume-div .header-info .avatar .avatar-img {
  padding: 2px;
  height: 70px;
}
.resume .resume-div .single-resume-div .header-info .name {
  color: #32325d;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}
.resume .resume-div .single-resume-div .header-info .header-info-subline {
  display: flex;
  align-items: center;
}
.resume .resume-div .single-resume-div .header-info .header-info-subline div {
  margin-right: 14px;
}
.resume .resume-div .single-resume-div .header-info .header-info-subline div img {
  height: 16px;
  width: 16px;
  margin-top: -2px;
  margin-right: 4px;
}
.resume .resume-div .single-resume-div .header-info .header-info-subline div span {
  font-size: 14px;
  line-height: 16px;
  color: #89909e;
}
.resume .resume-div .single-resume-div .header-info .sub-info {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #32325d;
  font-size: 14px;
}
.resume .resume-div .single-resume-div .header-info .sub-info .contact-info-body {
  display: flex;
  justify-content: center;
}
.resume .resume-div .single-resume-div .header-bottom-line {
  height: 4px;
  margin-top: 18px;
  background: repeating-linear-gradient(135deg, #3c3bbc, #3c3bbc 10px, #ffffff 10px, #ffffff 20px, #e84242 20px, #e84242 30px, #ffffff 30px, #ffffff 40px);
}
.resume .resume-div .single-resume-div .ant-tabs-bar {
  margin-bottom: 0 !important;
  border-color: #e3e7ed;
}
.resume .resume-div .single-resume-div .ant-tabs-bar .ant-tabs-nav-scroll {
  padding-left: 28px;
}
.resume .resume-div .single-resume-div .ant-tabs-bar .ant-tabs-tab {
  border-color: #e3e7ed;
}
.resume .resume-div .single-resume-div .ant-tabs-bar .ant-tabs-tab:hover,
.resume .resume-div .single-resume-div .ant-tabs-bar .ant-tabs-tab:active {
  color: #949ff2;
}
.resume .resume-div .single-resume-div .ant-tabs-bar .ant-tabs-tab-active {
  border-bottom-color: #fff;
  background-color: #fff;
  color: #6772e5;
}
.resume .resume-div .single-resume-div .ant-tabs-bar .ant-tabs-ink-bar {
  background-color: #6772e5;
  height: 4px;
}
.resume .resume-div .single-resume-div .zh-en-resume {
  padding: 0 28px 24px 28px;
  position: relative;
}
.resume .resume-div .single-resume-div .zh-en-resume .resume-div-header-code-button {
  position: absolute;
  right: 28px;
}
.resume .resume-div .single-resume-div .basic-info .basic-info-body {
  display: flex;
  justify-content: center;
}
.resume .resume-div .single-resume-div .basic-info .basic-info-body .basic-info-col {
  width: 50%;
}
.resume .resume-div .single-resume-div .basic-info .basic-info-body .basic-info-col .single-term {
  display: flex;
  align-items: center;
  line-height: 28px;
}
.resume .resume-div .single-resume-div .basic-info .basic-info-body .basic-info-col .single-term .single-term-key {
  color: #89909e;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  width: 100%;
}
.resume .resume-div .single-resume-div .basic-info .basic-info-body .basic-info-col .single-term .single-term-key .single-term-value {
  color: rgba(0, 0, 0, 0.85);
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}
.resume .resume-div .back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.small-dot {
  height: 6px;
  width: 6px;
  padding: 3px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: #6772e5;
}
.resume-section-header {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.resume-section-header .section-word {
  text-align: center;
  color: #32325d;
  font-weight: 400;
  line-height: 30px;
  font-size: 24px;
  margin-bottom: 0;
  position: relative;
}
.resume-section-header .section-word:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 15px;
  width: 110%;
  height: 20px;
  background: rgba(103, 137, 229, 0.16);
}
.resume-single-experience-section {
  margin-bottom: 20px;
}
.resume-single-experience-section .resume-primary-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}
.resume-single-experience-section .resume-primary-section-header .resume-primary-section-header-left {
  display: flex;
  align-items: center;
}
.resume-single-experience-section .resume-primary-section-header .resume-primary-section-header-left .resume-primary-section-title-name {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: flex-end;
}
.resume-single-experience-section .resume-primary-section-header .resume-primary-section-header-left .resume-primary-section-title-name .resume-primary-section-location {
  margin-left: 4px;
  font-size: 12px;
}
.resume-single-experience-section .resume-secondary-section .resume-secondary-section-single-term {
  line-height: 28px;
}
.resume-single-experience-section .resume-secondary-section .resume-secondary-section-single-term .resume-secondary-section-single-term-key {
  color: #89909e;
}
.resume-single-experience-section .resume-secondary-section .resume-secondary-section-single-term .resume-secondary-section-single-term-value {
  color: rgba(0, 0, 0, 0.85);
}
.resume-single-experience-section .resume-description-section {
  display: flex;
}
.resume-single-experience-section .resume-description-section .resume-description-section-key {
  color: #89909e;
  line-height: 28px;
  min-width: 70px;
}
.resume-single-experience-section .resume-description-section .resume-description-section-value {
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  max-width: 550px;
}
.resume-single-experience-section .resume-description-section .resume-description-section-value-no-max {
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
}
.resume-drawer-div .ant-drawer-body {
  padding: 0px !important;
}
.common-button {
  white-space: nowrap;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  color: #6772e5;
  text-decoration: none;
  transition: all 0.15s ease;
  color: #fff;
  background: #6772e5;
}
.common-button:focus,
.common-button:hover {
  color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  color: #fff;
  background-color: #7795f8;
}
.common-button:active {
  color: #555abf;
  background-color: #f6f9fc;
  transform: translateY(1px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #e6ebf1;
  background-color: #555abf;
}
.common-button-disabled {
  cursor: not-allowed;
}
.common-button-green {
  color: #fff;
  background: #3ecf8e;
}
.common-button-green:focus,
.common-button-green:hover {
  color: #fff;
  background-color: #74e4a2;
}
.common-button-green:active {
  color: #fff;
  background-color: #74e4a2;
}

.left-filter-div {
  margin-left: 8px;
  width: 400px;
}
.left-filter-div .left-filter-div-form {
  border: 1px solid #e8e8e8;
  margin: 8px 8px 8px 0;
  padding: 8px 20px 16px 20px;
  overflow-y: scroll;
  height: calc(100vh - 80px);
}
.left-filter-div .left-filter-div-form .left-filter-div-title {
  text-align: center;
  padding-top: 12px;
  color: #6772e5;
  font-size: 18px;
}
.left-filter-div .left-filter-div-form .left-filter-div-title-clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-row {
  display: flex;
  align-items: center;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-row .left-filter-div-form-name {
  width: 140px;
  margin-bottom: 12px;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-row .left-filter-div-form-name h5 {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: normal;
  text-align: left;
  line-height: 20px;
  margin-right: 20px;
  align-self: center;
  flex: 32% 1;
  text-transform: none;
  margin: 0;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-row .left-filter-div-form-value {
  width: 260px;
  margin-bottom: 12px;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-row .left-filter-div-form-value-advanced {
  width: 680px;
  margin-bottom: 12px;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-row .ant-slider-track {
  background-color: #6772e5;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-row .ant-slider-handle {
  border: solid 2px #6772e5;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .ant-divider-inner-text {
  color: #5f6368;
  font-weight: 100;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-expend-advanced {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-expend-advanced .left-filter-div-form-expend-advanced-span {
  cursor: pointer;
  color: #6772e5;
  text-align: right;
}
.left-filter-div .left-filter-div-form .left-filter-div-form-section .left-filter-div-form-submit-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-select-dropdown {
  height: 0;
  weight: 0;
}
.ant-select-dropdown .ant-empty {
  height: 0;
  width: 0;
}
.ant-select-dropdown svg {
  height: 0;
  width: 0;
}
.ant-select-dropdown div {
  height: 0;
  width: 0;
}
.ant-select-dropdown li {
  height: 0;
  width: 0;
}
.ant-select-dropdown ul {
  height: 0;
  width: 0;
}
@media only screen and (max-width: 1200px) {
  .left-filter-div {
    margin-left: 100px;
    width: 720px;
  }
}
@media only screen and (max-width: 768px) {
  .left-filter-div {
    width: 720px;
  }
}

.candidate-list-page .candidate-list-page-body {
  display: flex;
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-loading {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  width: 50%;
  flex-direction: column;
  height: 120px;
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-loading .candidate-list-page-body-loading-text {
  padding-top: 10px;
  color: #6772e5;
  font-weight: 600;
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-loading .candidate-list-page-body-error-text {
  padding-top: 10px;
  color: #ff6644;
  font-weight: 500;
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-div {
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 72px);
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-div .candidate-list-page-body-result-count {
  margin: 0;
  margin-top: 10px;
  padding-left: 28px;
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-div .candidate-list-page-body-result {
  margin-left: 20px;
  padding-bottom: 30px;
  width: 60%;
  min-width: 400px;
  max-width: 720px;
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-div .candidate-list-page-body-result .candidate-list-page-body-result-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.candidate-list-page .candidate-list-page-body .candidate-list-page-body-div .candidate-list-page-body-result .candidate-list-page-body-result-empty {
  padding-top: 30px;
}
@media only screen and (max-width: 1200px) {
  .candidate-list-page .candidate-list-page-body .candidate-list-page-body-result {
    width: 60%;
  }
}
@media only screen and (max-width: 576px) {
  .candidate-list-page .candidate-list-page-body .candidate-list-page-body-result {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-empty-section {
  height: 64px;
}

.root-div {
  height: 100%;
  overflow-y: scroll;
}

